import { ApolloProvider } from '@apollo/client'
import { type IdToken, useAuth0 } from '@auth0/auth0-react'
import { logger } from '@salescore/frontend-common'
import { useEffect, useState } from 'react'

import { useApolloClient } from '../../infra/graphql_client'
import { Loading } from '../components/layouts/Loading'
import { useRedirect } from '../hooks/useRedirect'

const Body = ({ token, children }: { token: string; children: JSX.Element }) => {
  const client = useApolloClient(token)
  if (client === undefined) {
    return <></>
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export const AuthenticatedApolloProvider = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0()
  const redirect = useRedirect()
  const [idToken, setIdToken] = useState<IdToken>()

  useEffect(() => {
    const f = async () => {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently()
        const idToken = await getIdTokenClaims()
        setIdToken(idToken)
      }
    }
    void f()
  }, [isAuthenticated, isLoading])

  if (isLoading) {
    return <Loading message="認証中..." />
  }

  if (!isAuthenticated) {
    logger.debug(`Authentication failed on Auth0. Redirect to /sign_in from ${location.href}`)

    redirect('/sign_in')
    return <Loading message="認証できませんでした。リダイレクト中..." />
  }

  if (idToken?.__raw === undefined) {
    return <Loading message="アクセストークンの取得中..." />
  }

  // TODO: 対応方法が分からなかった
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Body token={idToken.__raw}>{children as any}</Body>
}
