import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, type NormalizedCacheObject } from '@apollo/client'
import { VERSION } from '@salescore/buff-common'
import { useEffect, useState } from 'react'

const httpLink = new HttpLink({
  uri: process.env.GRAPHQL_URL,
  credentials: 'include',
  headers: {
    // NOTE: JWTを使って認証をするため、cookieは基本的に使わない。
    // integrationの設定時にOAuthのアクセストークンをサーバーサイドに渡すときのみセッションを用いるため必要。
    'Access-Control-Allow-Credentials': 'true',
  },
})

const createAuthLink = (token?: string) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: token === undefined ? '' : `Bearer ${token}`,
      },
    }))

    return forward(operation)
  })

const createApolloClient = (token?: string) => {
  const cache = new InMemoryCache()

  const client = new ApolloClient({
    cache,
    link: from([createAuthLink(token), httpLink]),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only', // cache-and-networkが選べなかった
      },
    },
    name: 'salescore-client',
    version: VERSION,
  })
  return client
}

export const useApolloClient = (token?: string) => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | undefined>()

  const awaitSetClient = () => {
    const client = createApolloClient(token)
    setClient(client)
  }

  useEffect(() => {
    awaitSetClient()
  }, [])

  return client
}
